'use strict'

import '@akerolabs/framework/src/polyfills/element.polyfill.js'
import '@akerolabs/framework/src/polyfills/events.polyfill.js'
import '@akerolabs/framework/src/polyfills/function.polyfill.js'
import '@akerolabs/framework/src/polyfills/array.polyfill.js'

import ready from '@akerolabs/framework/src/methods/ready.js'
import { add, remove } from '@akerolabs/framework/src/objects/listeners.js'
import * as logger from '@akerolabs/framework/src/objects/logger.js'
import ensure from '@akerolabs/framework/src/methods/ensure.js'
import * as scroll from '@akerolabs/framework/src/objects/scroll.js'
import iFrameResize from '../../public/js/vendor/iframeResizer.module.js'

ensure(window, '$akResize', () => {
  const akResize = Object.create(null)

  /**
   * This iFrame resizer code uses a modified version of the "iframeResizer"
   * we have it in the package json just as a refference to the version/library
   * used.
   *
   * There is a gulp method that will copy the lib into the "public/js/vendor"
   * folder, this is only to be used when upgrading the library.
   *
   * If you do need to update the library version you will need to manually edit
   * the copied file to remove the jquery/require/module export from the bottom
   * of the file and only bind it to "window.iframeResizer" otherwise many of our
   * clients will get issues with the pages resizing when they use require/almond
   */
  const isOldIE = navigator.userAgent.indexOf('MSIE') !== -1
  const heightCalculationMethod = isOldIE ? 'max' : 'bodyOffset'
  const iFrames = []
  const objType = (obj) => ({}).toString.call(obj)
  const log = false

  /**
   * @description These are the types of events the the akero forms emit and can
   * be picked up by GTM we need to listen for these as we need to communicate
   * with the forms when these happen
   * @type {Array}
   */
  const akEvents = [
    'conversion_complete',
    'akLoad',
    'akForm:',
    '_akForm:',
    '_ak:',
    'form:',
  ]

  /**
   * @description Options to pass into the iframe resize method
   * @type {Object}
   */
  const resizeOptions = {
    heightCalculationMethod,
    checkOrigin: false,
    inPageLinks: true,
    log,
  }

  /**
   * @description Ensures the return value is a function if the given value is a
   * function then we will simply return it, else we will just return an empty
   * function instead
   * @param {Mixed}
   * @return {Function}
   */
  const ensureFn = (fn) => (typeof fn === 'function' ? fn : function () {})

  /**
   * @param {HTMLElement} frame
   * @return {Boolean}
   * @description Checks if the iFrame should be added to the library, this will
   * check if it has already been added or if the data-init attribute has been
   * set to false
   */
  const shouldAdd = (frame) => {
    if (exists(frame)) return false
    if (frame.dataset && frame.dataset.init === 'false') return false
    return true
  }

  /**
   * @param {HTMLElement} a
   * @param {HTMLElement} b
   * @description Tries to check if the given iFrames are the same
   */
  const compareForm = (a, b) => {
    try {
      return a.dataset.src === b.dataset.src
    } catch (ex) {
      logger.error(ex)
      return -1
    }
  }

  /**
   * @param {HTMLElement} frame
   * @return {Boolean}
   * @description Checks if the form exists in the array of bound iFrames
   */
  const exists = (frame) => findIndex(frame) !== -1

  /**
   * @param {HTMLElement} frame
   * @return {Boolean}
   * @description Finds the position of a iFrame in the array of bound frames
   */
  const findIndex = (frame) => iFrames.findIndex((i) => compareForm(i, frame))

  /**
   * @description Returns a list of iFrame elements from the page
   * @param {String} selector - css selector to find the iFrames
   */
  const getIframes = (selector) => {
    if (log) logger.info('Fetching iFrames')
    try {
      const frames = document.querySelectorAll(selector)
      for (let i = 0, l = frames.length; i < l; i += 1) {
        const frame = frames[i]
        if (shouldAdd(frame)) iFrames.push(frame)
      }
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @description Sets all the event handlers and properties on the iFrame
   * @param {HTMLElement} iFrame
   */
  const setIFrame = (iFrame) => {
    if (log) logger.info('Setting up iFrame: ', iFrame.dataset.src)
    if (iFrame.resizeInitialized) {
      if (log) logger.info('iFrame already configured', iFrame.dataset.src)
      return
    }
    const loaded = loadIFrameSrc(iFrame)
    if (!loaded) {
      logger.info('Failed to load iFrame source: ', iFrame)
    } else {
      iFrame.resizeInitialized = true
      resizeIFrame(iFrame)
    }
  }

  /**
   * @description Takes the iFrame url from the data-src attribute, and sets it
   * on the src attribute, this will cause the iFrame to load into it's content
   * @param {HTMLElement} iFrame
   */
  const loadIFrameSrc = (iFrame) => {
    if (log) logger.info('Loading iFrame content: ', iFrame.dataset.src)
    try {
      const src = iFrame.getAttribute('data-src')
      const search = window.location.search
      let append = '&'

      if (search.length) {
        if (src.indexOf('?') === -1) append = '?'
        iFrame.src += `${src}${append}${search.substring(1, search.length)}`
      } else {
        iFrame.src = src
      }
    } catch (e) {
      logger.error(e)
      return false
    }

    return true
  }

  /**
   * @description Waits for the iFrame to be ready before firing the callback
   * @param {HTMLElement} iFrame - The iFrame we want to wait for
   * @param {Function} fn - The method to call when the iFrame has finished loading
   */
  const onIframeReady = (iFrame, fn) => {
    try {
      add(iFrame, 'load', (e) => ensureFn(fn)(e))
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @description Triggers the iFrame resize script
   * @param {HTMLElement} iFrame
   */
  const resizeIFrame = (iFrame) => {
    if (log) logger.info('Binding iFrame resize: ', iFrame.dataset.src)
    onIframeReady(iFrame, () => {
      try {
        if (log) logger.info('Resizing iFrame: ', iFrame.dataset.src)
        if (objType(iFrame.iFrameResizer) === '[object Object]') {
          if (log)
            logger.info('iFrameResize already bound for: ', iFrame.dataset.src)
        } else {
          iFrameResize(resizeOptions, iFrame)
        }
      } catch (ex) {
        logger.error(ex)
      }
    })
  }

  /**
   * @description Tries to check the data in the message event, this will look
   * for a specific event name from the data and return a boolean if we get a
   * match
   * @param {Array} types
   * @param {Object} evt
   * @param {Mixed} cb
   * @return {Boolean}
   */
  const checkForEvent = (types, evt, cb) => {
    const callback = ensureFn(cb)
    let result = false
    let data = null

    try {
      data = JSON.parse(evt.data)

      for (let i = 0; i < types.length; i += 1) {
        const type = types[i]
        result = data.event.indexOf(type) !== -1
        if (result) break
      }

      if (result) return callback(data)

      return result
    } catch (ex) {
      // We don't wanna log the errors here, these will be events that we are
      // not expecting and will throw on the JSON.parse call so I am not
      // interested in seeing these errors.
      return result
    }
  }

  /**
   * @param {Object} iFrame
   * @param {Object} data
   * @description Triggered when a _akForm:scroll event is picked up, this will
   * calculate the position the page needs to scroll to place the form at the
   * top
   */
  const handleScroll = (frame, { position, offset }) => {
    setTimeout(() => {
      const framePosition = scroll.position(frame, 'top left')
      const y = framePosition.y + position.y
      scroll.top({ y }, offset)
    }, 50)
  }

  /**
   * @description Event handler to run when receiving the post messages from the
   * child iFrame, this tries to look for the correct iFrame to deal with the
   * event, and fires the handleEvent method passing in the frame and data, to be
   * dealt with there
   * @param {Object} e
   */
  const eventHandler = (e) => {
    const frame = findFrame(e.source)

    checkForEvent(akEvents, e, (data) => {
      const event = new CustomEvent(data.event, { detail: data.meta })

      // We want to capture the scroll event and trigger the scroll.top method
      // and then return, since we don't want this event to be added to google
      // tag manager
      if (data.event === '_ak:scroll') {
        handleScroll(frame, data.meta)
        return
      }

      window.dispatchEvent(event)

      const evt = {
        event: data.event,
        akCampaign: data.meta.akCampaign,
        akTitle: data.meta.akTitle,
        title: document.title,
        eventCallback: () =>
          postTo(
            frame,
            JSON.stringify({
              event: `GTMParent:${data.event}`,
            }),
          ),
      }

      // We only want to push into the dataLayer if it exists on the window object
      // we don't want to create one if it does not exist, as we can just let the
      // child iFrame know to remove the conversion event from it's stack
      if (Array.isArray(window.dataLayer)) window.dataLayer.push(evt)

      // If google tag manager does not exist we need to fire the event callback
      // right away so it is removed from the forms conversion event stack
      if (objType(window.google_tag_manager) !== '[object Object]') {
        evt.eventCallback()
      }
    })

    handleEvent(frame, e.data)
  }

  /**
   * @description Tries to return the iFrame that matches the given source this
   * will either return the fame object, or it will return undefined if one was
   * not found
   * @param {Object} source
   * @return {HTMLElement|Undefined}
   */
  const findFrame = (source) =>
    iFrames.find((frame) => frame.contentWindow === source)

  /**
   * @description Tries to set the referer if needed or will resize the iframe
   * otherwise this picks up on postMessage data from the target iframe to do it
   * @param {HTMLElement} frame
   * @param {String} data
   */
  const handleEvent = (frame, data) => {
    try {
      if (typeof data === 'string' && data.indexOf('init') !== -1) {
        setReferrer(frame)
      }

      if (data === 'scrollTop') {
        const elemRect = frame.getBoundingClientRect()
        const bodyRect = document.body.getBoundingClientRect()
        const top = elemRect.top - bodyRect.top || 0
        const left = elemRect.left - bodyRect.left || 0
        window.scroll(left, top)
      }
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @description Sends a post message to the target iframe to set the referrer
   * @param {HTMLElement} frame
   */
  const setReferrer = (frame) => {
    try {
      const msg = JSON.stringify({ referrer: getReferrer() })
      postTo(frame, msg)
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @description Gets the referrer to pass to a child iFrame through post message
   * @return {String}
   */
  const getReferrer = () => document.referrer || document.referer || undefined

  /**
   * @description Attempts to post a message to the given frame
   * @param {HTMLElement} frame - The iFrame to post to
   * @param {String} msg - The message to send
   * @param {String} domain - The domain to restrict messages from
   */
  const postTo = (frame, msg, domain = '*') => {
    try {
      const { contentWindow } = frame
      contentWindow.postMessage(msg, domain)
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @description Sets up all of the akero iFrames in the DOM
   */
  const onReady = () => {
    if (log) logger.info('iFrame resizer ready!')
    try {
      getIframes('.ak-app-target')
      add(window, 'message', eventHandler)
      for (let i = 0, l = iFrames.length; i < l; i += 1) {
        setIFrame(iFrames[i])
      }
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @param {HTMLElement} elem
   * @description Tries to manually add a form to the library
   */
  const addForm = (elem) => {
    if (log) logger.info('Adding iFrame: ', elem.dataset.src)
    try {
      iFrames.push(elem)
      setIFrame(elem)
    } catch (ex) {
      logger.error(ex)
    }
  }

  /**
   * @param {HTMLElement} elem
   * @description Tries to manually remove a form from the library
   */
  const removeForm = (elem) => {
    if (log) logger.info('Removing iFrame: ', elem.dataset.src)
    try {
      const index = iFrames.findIndex((i) => compareForm(i, elem))
      if (index !== -1) {
        iFrames[index].iFrameResizer.close()
        iFrames.splice(index, 1)
      }
    } catch (ex) {
      logger.info(ex)
    }
  }

  ensure(window, 'AKEvents', () => ({ add, remove }))
  ensure(window, 'initAKForm', () => addForm)

  // Accidentally gave these the wrong name and edurank uses these functions
  ensure(akResize, 'addForm', () => addForm)
  ensure(akResize, 'removeForm', () => removeForm)

  // These function names make much more sense
  ensure(akResize, 'add', () => addForm)
  ensure(akResize, 'remove', () => removeForm)

  ensure(akResize, 'frames', () => iFrames)
  ensure(akResize, 'scroll', () => scroll)
  ready(onReady)

  return akResize
})
